import GridInfo from "../../GridInfo";
import ContainerActions from "../../ContainerActions";
import ContainerSearch from "../../ContainerSearch";
import styles from "./index.module.scss";
import { useContainerState, useContainerActions } from "../../../providers/ContainersProvider/ContainersProvider";
import MovingContainer from "./MovingContainer";

function ActionBar() {
  const { isMoving, currentGrid, currentContainer } = useContainerState(['isMoving', 'currentGrid', 'currentContainer'])
  const { dispatch } = useContainerActions()

  return (
    <div className={styles.container}>
      {isMoving && (
        <MovingContainer
          id={currentContainer?.container_id ?? 1}
          setIsMoving={(moving) => dispatch('isMoving', moving)}
        />
      )}
      <GridInfo /> <ContainerSearch />{" "}
      <ContainerActions />
    </div>
  );
}

export default ActionBar;
