import React from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { ContainerType, GridType } from "../../shared/types/ContainerTypes";

function Containers3d({
  currentGrid,
  setActiveContainerId,
}: {
  currentGrid: GridType;
  setActiveContainerId: (id?: number) => void
}) {
   const { containers, name } = currentGrid;
  const id = containers?.[0]?.container_id ? "container_id" : "id";

  return (
    <div
      className={clsx(
        styles["container-3d"],
        styles[`shadow-${containers.length}`]
      )}
    >
      {containers.sort().map((container, index) => (
        <div
          key={index}
          className={clsx(styles.containerbox, name.startsWith("B") && styles.buffer)}
          onClick={() => container[id] && setActiveContainerId(container[id])}
        >
          {container.number || container[id]}
          <div
            className={styles.before}
            onClick={() => container[id] && setActiveContainerId(container[id])}
          >
            {" "}
          </div>
          <div
            className={styles.after}
            onClick={() => container[id] && setActiveContainerId(container[id])}
          >
            {" "}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Containers3d;
