import React from "react";
import styles from "./index.module.scss";
import { Button, Spin } from "antd";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwt-api";
import ColumnData from "../../GridInfo/ColumnData";
import { Link } from "react-router-dom";

function MovingContainer({
  id,
  setIsMoving,
}: {
  id: number;
  setIsMoving: (moving: boolean) => void;
}) {
  const { data: res, isLoading } = useQuery(
    ["container", "containerinfo", id],
    () => jwtAxios.get(`master/container/${id}`)
  );
  const container = res?.data?.data;
  
 
  return (
    <div className={styles.move} style={{ display: "flex" }}>
      {isLoading ? (
        "Loading container data..."
      ) : (
        <div className={styles.containerInfo}>
          <p>
            Number
            <span>{container?.number}</span>
          </p>{" "}
          <p>
            Status
            <span>{container?.status_name}</span>
          </p>{" "}
          <p>
            Type
            <span>{container?.type_name}</span>
          </p>{" "}
          <p>
            Forwarder
            <span>
              {" "}
              <Link to={`/admin/company/${container?.forwarder?.id}`}>
                {container?.forwarder?.name}
              </Link>
            </span>
          </p>{" "}
          <p>
            Shipper
            <span>
              {" "}
              <Link to={`/admin/company/${container?.shipper?.id}`}>
                {container?.shipper?.name}
              </Link>
            </span>
          </p>{" "}
          <p>
            Consignee
            <span>
              <Link to={`/admin/company/${container?.consignee?.id}`}>
                {container?.consignee?.name}
              </Link>
            </span>
          </p>{" "}
          <p>
            Invoice
            <span>{container?.invoice_number}</span>
          </p>{" "}
          <p>
            B1
            <span>{container?.bl_number}</span>
          </p>{" "}
          <p>
            Created
            <span>{container?.created_at.split("T")[0]}</span>
          </p>
        </div>
      )}

      <Button onClick={() => setIsMoving(false)} type="primary">
        Cancel moving
      </Button>
    </div>
  );
}

export default MovingContainer;
