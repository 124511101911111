import React from "react";
import styles from "./index.module.scss";
import { GrCapacity, GrColumns } from "react-icons/gr";
// import { ContainerType, GridType } from "../../shared/types/ContainerTypes";
import { ContainerType, GridType } from "../../providers/ContainersProvider/typs";
import { PiRows } from "react-icons/pi";
import { RxLineHeight } from "react-icons/rx";
import { LuContainer } from "react-icons/lu";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import { Spin } from "antd";
import ColumnData from "./ColumnData";
import { Link } from "react-router-dom";
import { yardCapacity } from "../../shared/constants";
import { useContainersData } from "../../utils/ContainerProvider";
import { useContainerState } from "../../providers/ContainersProvider/ContainersProvider";

function InfoTable({
  currentGrid,
  
}: {
  currentGrid: GridType;
 }) {
   const { selectedContainerId: activeContainerId } = useContainerState(['selectedContainerId'])
  const { data, isLoading } = useQuery(
    ["container", "containerinfo", activeContainerId],
    () =>
      jwtAxios.get(
        `master/container/${activeContainerId}?only=id,invoice_number,created_at,bl_number,consignee,shipper,forwarder,consignee_id,shipper_id,forwarder_id,type,type_name,inside_name,number,status_name,status`
      ),
    {
      enabled: !!activeContainerId,
    }
  );

  const activeContainer: ContainerType = data?.data?.data;

  return (
    <div className={styles["info-table"]}>
      <h1>InfoTable</h1>

      <ColumnData
        variable="Grid"
        value={`${currentGrid.name} - ${
          currentGrid.containers.length
        }`}
      />

      {isLoading ? (
        <Spin>
          <div className={styles.row} style={{ height: 30 }}>
            <div className={styles.variable}></div>
            <div className={styles.value}> </div>
          </div>
        </Spin>
      ) : (
        activeContainer && (
          <>
            <ColumnData variable="Number" value={<Link to={`/admin/container/${activeContainer.id}`}>{activeContainer.number}</Link>} />
            <ColumnData variable="Status" value={activeContainer.inside_name} />
            <ColumnData variable="Type" value={activeContainer.type_name} />
            {activeContainer.forwarder && (
              <ColumnData
                variable="Forwarder"
                value={
                  <Link to={`/admin/company/${activeContainer.forwarder.id}`}>
                    {activeContainer.forwarder.name}
                  </Link>
                }
              />
            )}
            {activeContainer.shipper && (
              <ColumnData
                variable="Shipper"
                value={
                  <Link to={`/admin/company/${activeContainer.shipper.id}`}>
                    {activeContainer.shipper.name}
                  </Link>
                }
              />
            )}
            {activeContainer.consignee && (
              <ColumnData
                variable="Consignee"
                value={
                  <Link to={`/admin/company/${activeContainer.consignee.id}`}>
                    {activeContainer.consignee.name}
                  </Link>
                }
              />
            )}
            <ColumnData
              variable="Invoice"
              value={activeContainer.invoice_number}
            />
            <ColumnData variable="B1" value={activeContainer.bl_number} />
            {activeContainer.created_at && (
              <ColumnData
                variable="Created"
                value={activeContainer.created_at.split("T")[0]}
              />
            )}
          </>
        )
      )}
    </div>
  );
}

export default InfoTable;
