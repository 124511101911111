import { Button, Checkbox, Modal, notification } from "antd";
import { useState } from "react";
import PropTypes from "prop-types";
import { GrUserAdmin } from "react-icons/gr";
import jwtAxios from "../../auth/jwt-api";
import { RoleType } from "../../shared/types/RoleType";
import { UserType } from "../../shared/types";
import { systemRoles } from "../../shared/constants/Roles";
import { useAuthData } from "../../auth/JWTAuthProvider";
import { useQuery } from "react-query";

function RoleModal({user,refetch}:{user:UserType,refetch:()=>void}) {
  const [open, setOpen] = useState(false);
  const [currentRoles, setCurrentRoles] = useState<any>({});
   // const roles: RoleType[] = [];
  const {data:roles} = useQuery(["role","role-attachment"],()=>jwtAxios.get("master/role"))
 
  const onRoleChange = async () => {
    if (!user) {
      return;
    }
    try {
      const roles = Object.entries(currentRoles)
        .filter((role) => role[1])
        .map((role) => role[0]);
      await jwtAxios.put(`/master/user-sync-role/${user.uuid}`, { roles });

      notification.success({ message: "Role succesfuly changed" });
      refetch && refetch()
      setOpen(false);
    } catch (error) {
      notification.error({ message: "Cannot change role" });
    }
  };
 
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
  
          setCurrentRoles(
            Object.fromEntries(
              user?.roles?.map((role: string) => [role, true]) || []
            )
          );
          setOpen(true);
        }}
      >
         <GrUserAdmin />
      </Button>
      <Modal
        open={open}
        cancelText={"Cancel"}
        okText={"Save"}
        onCancel={() => setOpen(false)}
        width={600}
        onOk={onRoleChange}
        title={"Roles"}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 20,
          }}
        >
          {roles?.data?.data
            ?.filter((role:RoleType) => systemRoles.includes(role.name))
            ?.map((role:RoleType, index:number) => (
              <Checkbox
                key={index}
                style={{ marginLeft: 0 }}
                checked={currentRoles[role.name]}
                onClick={(e:any) => {
                  setCurrentRoles({
                    ...currentRoles,
                    [role.name]: e.currentTarget.checked,
                  });
                }}
              >
                {role.name}
              </Checkbox>
            ))}
        </div>

        <br />
        <hr />
        <br />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 20,
          }}
        >
          {roles?.data?.data
            ?.filter((role:RoleType) => !systemRoles.includes(role.name))
            ?.map((role:RoleType, index:number) => (
              <Checkbox
                key={index}
                style={{ marginLeft: 0 }}
                checked={currentRoles[role.name]}
                onClick={(e:any) => {
                  console.dir(e.currentTarget.checked);

                  setCurrentRoles({
                    ...currentRoles,
                    [role.name]: e.currentTarget.checked,
                  });
                }}
              >
                {role.name}
              </Checkbox>
            ))}
        </div>
      </Modal>
    </>
  );
}

export default RoleModal;
