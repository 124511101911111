import React, { useEffect, useState } from "react";
import { EnumType, MainPropType } from "../../shared/types";
import { Form, Modal, Select, notification } from "antd";
import { useQuery, useQueryClient } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import styles from "./index.module.scss";
import { ContainerType } from "../../shared/types/ContainerTypes";
import { useForm } from "antd/es/form/Form";
import getOptionsfrom from "../../utils/getOptionsFrom";
import { useContainerActions, useContainerState } from "../../providers/ContainersProvider/ContainersProvider";
import moment from "moment";

type FormType = { numbers: string[], inside: number }

function AddModal({ children }: MainPropType) {
  const queryClient = useQueryClient()
  const { fetchContainers } = useContainerActions()
  const { currentGrid, selectedContainerId, search } = useContainerState();
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm<FormType>();

  const { data: insides } = useQuery(["list-container-inside-types"], () => {
    return jwtAxios.get("/master/list-container-inside-types")
  });

  const { data: containerList, refetch } = useQuery("container-positionables", () => {
    return jwtAxios.get("/master/container-positionables", {
      params: {
        name
      }
    })
  });

  useEffect(() => {
    form.resetFields();
  }, [currentGrid]);


  const handleSubmit = async (data: FormType) => {
    setLoading(true);
    try {
      jwtAxios.post("/master/container-positioning", {
        ...data,
        grid_name: currentGrid?.name
      }).then(res => {
        setOpen(false);
        form.resetFields();
        fetchContainers();
        setTimeout(() => {
          queryClient.refetchQueries(['grid', currentGrid?.name])
          queryClient.refetchQueries('container-positionables')
          queryClient.refetchQueries(["container", "containerinfo", selectedContainerId])
          queryClient.refetchQueries(['container-actions', search])
        }, 300)
      }).catch(error => {
        notification.error({ message: JSON.stringify(error?.response?.data) });
      })
    } catch (error) {
      notification.error({ message: JSON.stringify(error) });
      console.log(error);
    }
    setLoading(false);
  };


  return (
    <>
      <div onClick={() => setOpen(!!currentGrid)} style={{ border: 40 }}> {children}</div>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={form.submit}
        confirmLoading={loading}
        width={500}
      >
        <div className={styles["add-content"]}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={console.log}
          >
            <div className={styles.form}>
              <Form.Item
                name={"container_number_list"}
                label={
                  <>
                    <span className={styles.required}>*</span> Container name
                  </>
                }
                rules={[
                  {
                    validator(_, data) {

                      if (data?.length) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Somthing wrong"));
                    },
                  },
                ]}
                validateTrigger={["onChange", "onBlur"]}
              >
                <Select
                  // onSearch={(value) => {
                  //   setName(value)
                  //   refetch()
                  // }}
                  // showSearch
                  mode="tags"
                  maxLength={1}
                  placeholder="Container name"
                  onChange={(data: string[]) => {
                    form.setFieldValue('numbers', data)
                  }}
                  options={(containerList?.data || []).map(
                    (container: ContainerType) => ({
                      value: container?.number,
                      label: `${container?.number} ${moment(container?.shipping_date).format('YY-MM-DD')}`,
                    })
                  )}
                />
              </Form.Item>

              <Form.Item
                name={"inside"}
                label="Status"
                rules={[
                  { required: true, message: "You should enter this field!" },
                ]}
              >
                <Select options={getOptionsfrom(insides?.data)} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default AddModal;
