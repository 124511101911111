import React, { useEffect, useState } from "react";
import { MainPropType } from "../../shared/types";
import { createContext, useContextSelector } from "use-context-selector";
import jwtAxios from "../../auth/jwt-api";
import {
  ContainerActionType,
  ContainerContextType,
  ContainerDataType,
  ContainerType,
  GridType,
} from "../../shared/types/ContainerTypes";
import setGridsXY from "../setGridsXY";
import { notification } from "antd";
import { Options } from "../../shared/types/option";

const defaultValues: ContainerContextType = {
  containers: { A: [], B: [], C: [] },
  currentGrid: null,
  currentContainer: null,
  isLoading: false,
  isError: false,
  isMoving: false,
};

const DataContext = createContext<ContainerContextType>(defaultValues);
const DataActionsContext = createContext<ContainerActionType>({
  fetchContainers: () => { },
  setIsMoving: () => { },
  moveContainer: () => { },
  setCurrentGrid: () => { },
  moveOutContainer: async () => { },
  setSelectedContainerId: () => { },
});

export const useContainersData = (
  selector: (value: ContainerContextType) => any
) => {
  return useContextSelector(DataContext, selector);
};
export function useContainersActions<T = any>(
  selector: (value: ContainerActionType) => T
) {
  return useContextSelector<ContainerActionType, T>(DataActionsContext, selector);
};

function ContainerProvider({ children }: MainPropType) {
  const [data, setData] = useState<ContainerContextType>(defaultValues);
  const [selectedContainerId, setSelectedContainerId] = useState<number>();
  const [isMoving, setIsMoving] = useState<boolean>(false);

  const fetchContainers = async () => {
    setData({ ...data, isLoading: true });
    try {
      const res = await jwtAxios("/master/container-matrix");
      const containers = res?.data && setGridsXY(res.data);
      if (!data.currentGrid) {
        return setData({
          ...data,
          containers,
          isLoading: false,
        });
      }
      const { x_axis, y_axis, yard } = data.currentGrid;

      const currentGrid =
        containers[yard as keyof ContainerDataType][(x_axis as number) - 1][
        (y_axis as number) - 1
        ];

      setData({ ...data, containers, currentGrid, isLoading: false });
    } catch (error) {
      console.log({ error });
      setData({ ...data, isLoading: false });
    }
  };

  const setCurrentGrid = (grid: GridType | null, container?: ContainerType) => {
    let currentGrid = grid;
    if (!currentGrid) {
      if (!container?.position?.grid_name) return;

      const position = container.position.grid_name.split(".");
      currentGrid =
        data.containers[position[0] as "A" | "B" | "C"][+position[2] - 1][
        +position[1] - 1
        ];
    }

    const currentContainer =
      currentGrid?.containers.find(
        (container) => currentGrid?.containers.length === container.z_axis
      ) || null;
    setSelectedContainerId(container?.id || container?.container_id);
    setData({ ...data, currentGrid, currentContainer });
  };

  const moveContainer = async (gridName?: string, onMoved = async () => {}) => {
    let response = await jwtAxios.put(`/master/container-move/${data.currentGrid?.name}`, {
      grid_name: gridName,
      state: 10,
      status: 10,
    });
    try {
      notification.success({ message: "Container successfully moved!" });
      setIsMoving(false);
      fetchContainers();
      await onMoved()
    } catch (error) {
      notification.error({ message: "Error occured during moving!" });
    }
  };
  const moveOutContainer = async () => {
    try {
      if (!data.currentGrid?.name) {
        return;
      }
      const res = await jwtAxios.delete(
        `/master/container-output/${data.currentGrid.name}`
      );
      notification.success({ message: "Successfully moved out!" });
      fetchContainers();
    } catch (error) {
      console.log({ error });

      notification.error({ message: "Error occured during moving out!" });
    }
  };

  console.log();

  return (
    <DataActionsContext.Provider
      value={{
        fetchContainers,
        setCurrentGrid,
        setIsMoving,
        moveContainer,
        moveOutContainer,
        setSelectedContainerId,
      }}
    >
      <DataContext.Provider value={{ ...data, isMoving, selectedContainerId }}>
        {children}
      </DataContext.Provider>
    </DataActionsContext.Provider>
  );
}

export default ContainerProvider;
