import { yardCapacity } from "../shared/constants";
import { ContainerDataType } from "../shared/types/ContainerTypes";
 

function  setGridsXY(containers: ContainerDataType) {
  const containerEntries = Object.entries(containers);
   containerEntries.forEach((yard) =>
    yard[1]?.forEach((row, i) =>
      row.forEach((grid, j) => {
        grid.capacity =
          yardCapacity[
            yard[0] as keyof {
              A: number;
              B: number;
            }
          ];
          grid.yard = yard[0]
           
        grid.x_axis = i + 1;
        grid.y_axis = j + 1;
      })
    )
  );
  return Object.fromEntries(containerEntries);
}

export default setGridsXY;
