import styles from "./index.module.scss";
import { MainPropType } from "../../shared/types";
import { GridType } from "../../shared/types/ContainerTypes";
import clsx from "clsx";
import { Popconfirm } from "antd";
import { useQueryClient } from "react-query";
import { useContainerActions, useContainerState } from "../../providers/ContainersProvider/ContainersProvider";

const colors = ["green", "yellow", "orange", "red", "coral"];

function Grid({
  children,
  style,
  grid,
  type,
}: { grid?: GridType; type?: "buffer" } & MainPropType) {
  const queryClient = useQueryClient();
  const { relocatingContainer, setCurrentGrid, setActiveContainerId } = useContainerActions()
  const { currentGrid, currentContainer, isMoving, search, selectedContainerId } = useContainerState()
  const isFull = grid?.capacity === grid?.containers.length;
  const isSelected = currentGrid?.name && currentGrid?.name === grid?.name;

  const handleConfirm = () => {
    if (!grid) {
      return;
    }
    relocatingContainer(grid, {
      onSuccess() {
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries(['container-actions', search])
        if (selectedContainerId == currentContainer?.container_id) {
          setActiveContainerId(undefined);
          queryClient.refetchQueries(["container", "containerinfo", selectedContainerId])
        }
      }
    });
  };

  const gridEl = (
    <div
      // onMouseEnter={(ev) => console.log("Mouse entered")}
      // onMouseLeave={(ev) => console.log("Mouse Leave")}
      onClick={() => {
        if (!grid) {
          return;
        }

        !isSelected && !isMoving && setCurrentGrid(grid);
      }}
      className={clsx(
        styles.conatiner,
        isSelected && styles.active,
        grid && isMoving && isFull && styles.disabled
      )}
      style={{
        background:
          type === "buffer"
            ? grid?.containers.length
              ? "var(--color-blue)"
              : "#cfcfcf"
            : grid?.containers.length
              ? `var(--color-${colors[grid.containers.length - 1]})`
              : "white",
        ...(style || {}),
      }}
    >
      {children ?? <div style={{ opacity: '0.6' }}>{grid?.name}</div>}
    </div>
  );

  return !isSelected && isMoving && !isFull ? (
    <Popconfirm
      title="Moving here!"
      description={`Are you sure you wanna move container "${currentContainer?.number}" to here?`}
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      {gridEl}
    </Popconfirm>
  ) : (
    gridEl
  );
}

export default Grid;
