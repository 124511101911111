import React from "react";
import { Context, useContextSelector } from "use-context-selector";

type Selector<T, K extends keyof T = never> = {
    [A in K]: A extends keyof T ? T[A] : never
}
export function makeSelector<T>(context: Context<T>) {
    return function <K extends keyof T>(keys?: K[]) {
        function selector(value: T) {
            if (keys) {
                let data = {} as T
                for (const key of keys) {
                    data[key] = value[key]
                }
            }
            return value as Selector<T, K>
        }
        return useContextSelector<T, Selector<T, K>>(context, selector)
    }
}

export type Dispatch<T> = (key: keyof T, value: T[keyof T]) => void
export type Callback<T> = (dispatch: Dispatch<T>, state: T) => void
export type Dispatcher<T> = (callback: Callback<T>) => void

export function makeDispatcher<T>(state: T, setState: React.Dispatch<React.SetStateAction<T>>): Dispatcher<T> {
    return function (callback: Callback<T>) {
        const dispatch: Dispatch<T> = (key, value) => {
            let obj = { ...state }
            obj[key] = value
            setState(obj)
        }
        callback(dispatch, state)
    }
}
export function makeDispatch<T>(state: T, setState: React.Dispatch<React.SetStateAction<T>>): Dispatch<T> {
    const dispatch: Dispatch<T> = (key, value) => {
        let obj = { ...state }
        obj[key] = value
        setState(obj)
    }
    return dispatch
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export type StatePayload<T> = {
    state: keyof T,
    payload: T[keyof T]
}

export function makeReducer<T>(initialState: T) {
    return (state: T, action: StatePayload<T>) => {
        let obj = { ...state }
        obj[action.state] = action.payload
        return obj
    }
}

export type ActionsType<T, A extends (...args: any) => any> = ReturnType<A> & {
    dispatch: Dispatch<T>
    dispatcher: Dispatcher<T>
    setState: SetState<T>
}

export interface ActionsControllers<T> {
    state: T,
    dispatch: Dispatch<T>,
    dispatcher: Dispatcher<T>,
    setState: SetState<T>
}


export interface ActionTriggers<T = any, E = any> {
    onSuccess?: (data?: T) => void
    onError?: (error?: E) => void
}

export type BaseEnum = { code: string; name: string }