import Train from "../../../components/sections/Train";
import ActionBar from "../../../components/sections/ActionBar";
import Yard from "../../../components/sections/Yard";
import { Spin } from "antd";
import { useContainerState } from "../../../providers/ContainersProvider/ContainersProvider";

function MainPage() {
  const { containers, isLoading, isMoving, currentContainer, currentGrid, selectedContainerId } = useContainerState()
  return (
    <>
    {/* <div>{JSON.stringify(currentGrid)}</div>
    <div>{JSON.stringify(currentContainer)}</div> */}
    {/* <div>{selectedContainerId}</div> */}
      <Spin spinning={isLoading}>
        <Yard section={containers.A} name="A" key={1} />
        <Train section={containers.B} name="B" style={{ margin: "20px 0" }} />
        <Yard section={containers.C} name="C" reverse key={2} />
      </Spin>
      <ActionBar />
    </>
  );
}

export default MainPage;
