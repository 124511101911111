import { ContainersState } from "./typs";

export const containersState: ContainersState = {
    containers: { A: [], B: [], C: [] },
    currentGrid: null,
    currentContainer: null,
    isLoading: false,
    isError: false,
    isMoving: false,
    search: ''
}
