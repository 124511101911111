import React, { useEffect } from "react";
import AuthLayout from "./AuthLayout";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useAuthData } from "../auth/JWTAuthProvider";
import MainLayout from "./MainLayout";
import { authRoutes } from "../pages/auth";
import { mainRoutes } from "../pages/main";
import useRouteGenerator from "../utils/routeGenerator";
import styles from "../index.module.scss";
import { authorithedRoutes, unauthorithedRoutes } from "../pages/routeConfig";
import AdminLayout from "./AdminLayout";
import Loading from "../components/Loading";
import {
  useContainersActions,
  useContainersData,
} from "../utils/ContainerProvider";

function AppLayout() {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuthData();
  const routes = useRouteGenerator(
    isAuthenticated ? authorithedRoutes : unauthorithedRoutes
  );
  const { pathname } = useLocation();
  const isAdminLayout = pathname.startsWith("/admin");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {isAuthLoading ? (
        <Loading spinning={true} />
      ) : !isAuthenticated ? (
        <AuthLayout>{routes}</AuthLayout>
      ) : isAdminLayout ? (
        <AdminLayout>{routes}</AdminLayout>
      ) : (

        <MainLayout>{routes}</MainLayout>
      )}
    </div>
  );
}

export default AppLayout;
