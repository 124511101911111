import React from "react";
import { containerInfo } from "../../../shared/constants";
import styles from "./index.module.scss";
import { GridType } from "../../../shared/types/ContainerTypes";
import Grid from "../../Grid";

function Yard({ section, name, reverse = false }: { name: string, section: GridType[][], reverse?: boolean }) {
  return (
    <>
      {!reverse ?
        <div className={styles.conatiner}  >
          <div className={styles["row-numbers"]}>
            {section.map((_, index: number) => (
              <div key={index + 1} style={{ background: "white" }}>{index + 1}</div>
            ))}{" "}
            <div style={{ background: "white" }}>No</div>
          </div>{" "}
          <div className={styles["columns"]}>
            <div className={styles["chunks-container"]}>
              {section.map((cols, i) =>
                cols.map((grid, j) => (
                  <Grid key={j} grid={grid} style={{ gridColumn: `${grid.y_axis}/${grid.y_axis + 1}`, gridRow: `${grid.x_axis}/${grid.x_axis + 1}` }}>
                  </Grid>
                ))
              )}
            </div>
            <div className={styles["column-numbers"]}>
              {section[0]?.map((_, index: number) => (
                <Grid key={index + 1} style={{ background: "white" }}>{index + 1}</Grid>
              ))}
            </div>
          </div>
          <div className={styles.name}>{name} </div>
        </div>
        :
        <div className={styles.conatiner}  >
          <div className={styles["row-numbers"]}>
            <div style={{ background: "white" }}>No</div>
            {section.map((_, index: number) => (
              <div key={index+1} style={{ background: "white" }}>{section.length - index}</div>
            ))}{" "}
          </div>{" "}
          <div className={styles["columns"]}>
            <div className={styles["column-numbers"]}>
              {section[0]?.map((_, index: number) => (
                <Grid key={index+1} style={{ background: "white" }}>{index + 1}</Grid>
              ))}
            </div>
            <div className={styles["chunks-container"]}>
              {section.map((cols, i) =>
                section[section.length - i - 1].map((grid, j) => (
                  <Grid key={j} grid={grid} style={{}}>
                  </Grid>
                ))
              )}
            </div>
          </div>
          <div className={styles.name}>{name} </div>
        </div>
      }
    </>
  );
}

export default Yard;
