import React, { useState } from 'react'
import { MainPropType } from '../../shared/types'
import { Modal, Spin } from "antd";

import styles from "./index.module.scss";
import { useContainerActions, useContainerState } from '../../providers/ContainersProvider/ContainersProvider';
import { useQueryClient } from 'react-query';

function SendModal({ children }: MainPropType) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { returningContainer, setActiveContainerId } = useContainerActions(['returningContainer', 'setActiveContainerId'])
  const { currentContainer, currentGrid, search, selectedContainerId } = useContainerState();

  const handleOk = async () => {
    if (loading) return;
    setLoading(true);
    await returningContainer({
      onSuccess() {
        queryClient.refetchQueries(['grid', currentGrid?.name])
        queryClient.refetchQueries('container-positionables')
        queryClient.refetchQueries(['container-actions', search])
        setOpen(false);
        setLoading(false);
        if (selectedContainerId == currentContainer?.container_id) {
          setActiveContainerId(undefined);
          queryClient.refetchQueries(["container", "containerinfo", selectedContainerId])
        }
      }
    })
  };

  if (currentGrid?.containers.length == 0) {
    return <></>
  }
  return (
    <>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Modal open={open} onOk={handleOk} onCancel={() => setOpen(false)}>
        <Spin spinning={loading}>
          <p className={styles["move-out-content"]}>
            Are you sure you wanna move out "
            {currentContainer?.number}"
            container?
          </p>
        </Spin>
      </Modal>
    </>
  );
}

export default SendModal