import Containers3d from "./Containers3d";
import InfoTable from "./InfoTable";
import styles from "./index.module.scss";
import { Spin } from "antd";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import { useContainerState, useContainerActions } from "../../providers/ContainersProvider/ContainersProvider";

function GridInfo() {
  const { currentGrid } = useContainerState(['currentGrid', 'selectedContainerId'])
  const { setActiveContainerId } = useContainerActions()
  
  const { data: grid, refetch } = useQuery(
    ["grid", currentGrid?.name],
    () =>jwtAxios.get(`/master/grid/${currentGrid?.name}?only=yard,containers,name`),
    {
      select: (res) => res?.data?.data,
      enabled: !!currentGrid?.name,
    }
  );

  return (
    <div className={styles.container}>
      {(grid || currentGrid) && (
        <>
          <Containers3d
            currentGrid={grid || currentGrid}
            setActiveContainerId={(id) => setActiveContainerId(id)}
          />{" "}
          <InfoTable currentGrid={grid || currentGrid} />
        </>
      )}
    </div>
  );
}

export default GridInfo;
