import React from "react";
import { MainPropType } from "../../../shared/types";
import styles from "./index.module.scss";
import trainimage from "../../../assets/images/trainimage.png";
import { GridType } from "../../../shared/types/ContainerTypes";
import Grid from "../../Grid";

function Train({ style, section ,name}: {name:string, section: GridType[][] } & MainPropType) {
   
  return (
    <div style={style} className={styles.container}>
      <img src={trainimage} alt="" />
      <div className={styles["yard-b"]}>
        {section.map((column, i) => (
          <Grid key={i} grid={section[i][0]} type="buffer" />
        ))}
      </div>
      <div className={styles.yardname} style={{
        fontSize:'20px',padding:5
      }}> {name}</div>
    </div>
  );
}

export default Train;
