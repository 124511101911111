import styles from "./index.module.scss";
import { Button } from "antd";
import AddModal from "../Modals/AddModal";
import SendModal from "../Modals/SendModal";
import UnloadingModal from "../Modals/UnloadingModal";
import { Link } from "react-router-dom";
import { useContainerState, useContainerActions } from "../../providers/ContainersProvider/ContainersProvider";

function ContainerActions() {
  const { currentGrid, currentContainer } = useContainerState()
  const { dispatch } = useContainerActions()
  const isCurrentGridFull = currentGrid ? currentGrid.containers.length >= currentGrid.capacity : false
  return (
    <div className={styles.container}>
      <h1>Actions</h1>
      <div className={styles.buttons}>
        {currentGrid && <AddModal>
          <Button
            type="primary"
            style={{ background: "var(--color-red)" }}
            block disabled={isCurrentGridFull}
          >
            Add
          </Button>
        </AddModal>}
        {currentGrid?.containers && currentGrid?.containers.length > 0 &&
          <Button
            type="primary"
            style={{ background: "var(--color-green)", color: "#2b2b2b" }}
            onClick={() => dispatch('isMoving', true)}
          >
            Move
          </Button>}
        {currentGrid?.containers && currentGrid?.containers.length > 0 &&
          <UnloadingModal>
            <Button
              type="primary"
              style={{ background: "var(--color-orange)", color: "#2b2b2b" }}
              block
            >
              Unload
            </Button>
          </UnloadingModal>
        }
        {currentGrid?.containers && currentGrid?.containers.length > 0 &&
          <SendModal>
            <Button
              type="primary"
              style={{ background: "var(--color-coral)", color: "#fff" }}
              block
            >
              Return
            </Button>
          </SendModal>
        }
        <Link to="/admin/table?page=container">
          <Button
            type="primary"
            style={{ background: "var(--color-yellow)", color: "#2b2b2b" }}
            block
          >
            Admin
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ContainerActions;
